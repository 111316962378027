<template>
  <div
    class="article-card"
    :style="{ width: cardWidth }"
    @click="toContentDetail"
  >
    <div class="article-card-cover">
      <img
        :src="article.posterLink"
        alt="图片"
        class="article-card-cover-img"
        v-if="article.posterLink"
      />
      <img
        src="../assets/img/xwTeacher/xw-logo-t.png"
        alt="图片"
        class="article-card-cover-img"
        v-else
      />
    </div>
    <div class="article-card-body">
      <div class="body-title">
        {{ article.name }}
      </div>
      <div class="body-content">
        {{ article.summary }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "article-card",
  props: {
    article: {
      type: Object,
      default: () => ({}),
    },
    cardWidth: {
      type: String,
      default: "49%",
    },
  },
  methods: {
    toContentDetail() {
      this.$router.push("/xwContentDetail?id=" + this.article.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.article-card {
  min-width: 421px;
  width: 49%;
  margin-bottom: 28px;
  display: flex;
  .article-card-cover {
    width: 100px;
    height: 100px;
    min-width: 100px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    overflow: hidden;
    box-sizing: border-box;
    .article-card-cover-img {
      height: 100%;
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      display: block;
    }
  }
  .article-card-body {
    margin-left: 12px;
    .body-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #252a33;
      margin-bottom: 16px;
      display: -webkit-box;
      word-break: break-all;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      white-space: normal !important;
      &:hover {
        color: #00c792;
        cursor: pointer;
      }
    }
    .body-content {
      font-size: 14px;
      line-height: 20px;
      color: #828993;
      display: -webkit-box;
      word-break: break-all;
      overflow: hidden;
      -webkit-line-clamp: 3;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      white-space: normal !important;
    }
  }
}
@media screen and (max-width: 900px) {
  .article-card {
    min-width: 100%;
    width: 100%;
    margin-bottom: 10px;
    .article-card-body {
      .body-title {
        font-size: 13px;
      }
      .body-content {
        font-size: 13px;
      }
    }
  }
}
</style>