<template>
  <div class="xwHome">
    <div class="header-container">
      <div class="home-logo">
        <img class="logo-img" src="../../assets/img/xwTeacher/xw-logo-t.png" alt="小文老师" />
        小文老师写作中心
      </div>
      <div v-if="userInfo" class="my-question" @click="toMyQuestion">
        我的提问
      </div>
      <div v-if="!userInfo" class="login-btn" @click="toLogin">登录</div>
    </div>
    <div class="banner">
      <div class="nav">
        <img src="../../assets/img/xwTeacher/nav1.png" alt="写作课程" @click="toPutQuestion" />
        <img src="../../assets/img/xwTeacher/nav2.png" alt="教师团队" @click="toPutQuestion" />
        <img src="../../assets/img/xwTeacher/nav3.png" alt="写作沙龙" @click="toPutQuestion" />
        <img src="../../assets/img/xwTeacher/nav4.png" alt="咨询服务" @click="toPutQuestion" />
        <img src="../../assets/img/xwTeacher/nav5.png" alt="资源工具" @click="toPutQuestion" />
      </div>
    </div>
    <div class="home-header">
      <div class="home-header-container">
        <div class="home-logo">
          <img class="logo-img" src="../../assets/img/xwTeacher/xw-logo-t.png" alt="logo" />
          小文老师
        </div>
        <div class="search-input-container">
          <input class="search-input" type="text" placeholder="论文/文章/资料/学校" v-model="searchName" />
          <button class="search-input-button el-icon-search" @click="toSearch">
            搜索
          </button>
        </div>
        <div class="home-banner">
          <div class="banner-left" @click="toPutQuestion">
            <div class="banner-left-title">问老师</div>
            <div class="banner-left-sub">资深老师在线</div>
          </div>
          <div class="banner-right">
            <div class="banner-right-item" v-for="(item, index) in bannerArr" :key="index" @click="toPutQuestion">
              <div class="item-info">
                <div class="item-info-title">{{ item }}</div>
                <!-- <div class="item-info-sub">权威资料百科</div> -->
              </div>
              <img class="item-img" src="../../assets/img/xwTeacher/search-green.png" alt="图标" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-title">
        <h2 class="content-title-text">大家都在问</h2>
        <span class="content-title-more" @click="toAllWenList">查看更多</span>
      </div>
      <div class="content-info">
        <div class="question-module">
          <div class="question-card" v-for="(item, index) in allWenList" :key="index" @click="toAllWenDetail(item)">
            <div class="dialog-ask">
              <div class="common-avatar">问</div>
              <div class="dialog-ask-content">{{ item.content }}</div>
            </div>
            <div class="dialog-answer">
              <img class="avatar-default" src="../../assets/img/xwTeacher/xw-logo-t.png" alt="小文老师" />
              <div class="dialog-ask-content" v-html="item.reply"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-title">
        <h2 class="content-title-text">论文资料</h2>
        <span class="content-title-more" @click="toContentList">查看更多</span>
      </div>
      <div class="content-info">
        <div class="article-module">
          <article-card :article="item" v-for="(item, index) in contentList" :key="index"></article-card>
        </div>
      </div>
    </div>
    <div class="footer-view">
      <span style="margin-right: 20px">版权所有：北京慧锐博学教育科技有限公司</span>
      <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">京ICP备20020529号-2</a>
    </div>
  </div>
</template>
<script>
import ArticleCard from "../../components/ArticleCard.vue";
export default {
  name: "xwHome",
  components: {
    ArticleCard,
  },
  data() {
    return {
      bannerArr: [
        "选题分析",
        "提纲推荐",
        "资料搜集",
        "在线写作",
        "论文纠错",
        "论文查重",
        "论文排版",
        "参考文献",
      ],
      userInfo: null,
      allWenList: [],
      contentList: [],
      searchName: "",
    };
  },
  created() {
    document.title = "小文老师";
    if (this.$route.query.code) {
      this.loginByCode(this.$route.query.code);
    } else {
      this.getUserInfo();
    }
    this.getAllWen();
    this.getContentList();
  },
  mounted() {
    console.log(this.$route);
  },
  methods: {
    toLogin() {
      // console.log('123---',location)
      this.axios
        .post(
          "/api/user/account/anon/loginUrl",
          "https://www.xiaowenlaoshi.com/education-trainee/#/xwHome/",
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          location.href = res.data;
        })
        .catch(() => {});
    },
    loginByCode(code) {
      this.axios
        .post("/api/user/account/anon/loginByCode", {
          code: code,
        })
        .then((res) => {
          this.userInfo = res.data;
          this.$router.replace("/xwHome");
        })
        .catch(() => {});
    },
    getUserInfo() {
      this.axios
        .get("/api/user/account/loginInfo")
        .then((res) => {
          this.userInfo = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toPutQuestion() {
      if (!this.userInfo) {
        this.$message({
          type: "warning",
          message: "请先登录",
        });
        return;
      }
      this.$router.push("/putQuestion");
    },
    toMyQuestion() {
      this.$router.push("/myQuestion");
    },
    getContentList() {
      this.axios
        .get("/api/common/imageText/anon/normalList", {
          params: {
            limit: 2,
            offset: 0,
            mainType: "XW_RESOURCE",
            name: "",
          },
        })
        .then((res) => {
          console.log(res);
          this.contentList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toContentList() {
      this.$router.push("/xwContentList");
    },
    toSearch() {
      if (!this.searchName) {
        this.$message({
          type: "warning",
          message: "请输入搜索内容",
        });
        return;
      }
      this.$router.push("/xwContentList?searchName=" + this.searchName);
    },
    getAllWen() {
      this.axios
        .post("/api/user/advisory/anon/resource/list", {
          keyword: "",
          limit: 2,
          offset: 0,
        })
        .then((res) => {
          console.log(res);
          this.allWenList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toAllWenList() {
      this.$router.push("/allWenList");
    },
    toAllWenDetail(item) {
      this.$router.push({
        name: "allWenDetail",
        params: {
          detail: item,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.xwHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  min-height: 100vh;
  position: relative;
  .header-container {
    max-width: 1180px;
    width: 100%;
    height: 60px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0;
    .home-logo {
      height: 60px;
      color: #000;
      text-align: center;
      line-height: 60px;
      font-size: 20px;
      display: flex;
      align-items: center;
      .logo-img {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        background-color: #ffffff;
        margin-right: 10px;
      }
    }
    .my-question,
    .login-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 15px;
      color: #000;
      &:hover {
        cursor: pointer;
        color: #00c792;
      }
      .avatar {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }
  .banner {
    width: 100%;
    height: 800px;
    background: url(../../assets/img/xwTeacher/banner-bg.jpeg) no-repeat center
      top;
    background-size: contain;
    border-top: 2px solid #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 100px;
    box-sizing: border-box;
    .nav {
      max-width: 1180px;
      width: 100%;
      height: 77px;
      background: #fff;
      box-shadow: 0 0 0.4rem #e7f3ef;
      border-radius: 0.25rem;
      border: 1px solid #e7f3ef;
      display: flex;
      align-items: center;
      justify-content: space-around;
      img{
        width: 123px;
        height: 22px;
        transition: all 0.2s linear 0s;
        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }
      }
    }
  }

  .home-header {
    width: 100%;
    margin-bottom: 40px;
    height: 558px;
    .home-header-container {
      background: url(../../assets/img/xwTeacher/home-header-bg.png) no-repeat
        100% 100%;
      background-size: cover;
      height: 526px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
      .home-logo {
        height: 49px;
        margin-top: 100px;
        color: #ffffff;
        text-align: center;
        line-height: 49px;
        font-size: 30px;
        display: flex;
        align-items: center;
        .logo-img {
          width: 38px;
          height: 38px;
          border-radius: 10px;
          background-color: #ffffff;
          margin-right: 10px;
        }
      }
      .search-input-container {
        margin-top: 31px;
        box-shadow: 0 2px 1px 0 hsla(215, 9%, 63%, 0.05);
        display: flex;
        align-items: center;
        position: relative;
        width: 680px;
        height: 46px;
        background-color: #fff;
        .search-input {
          display: inline-block;
          flex: 1 1;
          text-indent: 10px;
          border: 1px solid #e8e9eb;
          font-size: 14px;
          height: 44px;
          line-height: 44px;
          color: #444c57;
          outline: none;
          font-family: PingFang SC, Helvetica Neue, Helvetica, Hiragino Sans GB,
            Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial, sans-serif;
        }
        .search-input-button {
          width: 128px;
          height: 46px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #00c792;
          font-weight: 500;
          line-height: 16px;
          color: #fff;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }
      .home-banner {
        display: flex;
        max-width: 1180px;
        width: 100%;
        margin-top: 80px;
        .banner-left {
          width: 226px;
          min-width: 226px;
          height: 252px;
          border-radius: 4px;
          box-shadow: 0 6px 12px 0 hsla(215, 9%, 63%, 0.2);
          background-image: url(../../assets/img/xwTeacher/banner-left-bg.png);
          background-size: contain;
          background-repeat: no-repeat;
          padding: 36px 20px;
          box-sizing: border-box;
          background-color: #fff;
          display: block;
          cursor: pointer;
          .banner-left-title {
            top: 36px;
            font-size: 28px;
            font-weight: 500;
            line-height: 32px;
            color: #252a33;
            font-family: PingFang SC, Helvetica Neue, Helvetica,
              Hiragino Sans GB, Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial,
              sans-serif;
          }
          .banner-left-sub {
            font-size: 12px;
            line-height: 16px;
            color: #252a33;
            margin-top: 8px;
            font-family: PingFang SC, Helvetica Neue, Helvetica,
              Hiragino Sans GB, Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial,
              sans-serif;
          }
        }
        .banner-right {
          display: flex;
          flex-flow: row wrap;
          min-width: 942px;
          .banner-right-item {
            display: flex;
            padding: 36px 20px;
            align-items: center;
            width: 226px;
            height: 120px;
            border-radius: 4px;
            box-shadow: 0 6px 12px 0 hsla(215, 9%, 63%, 0.2);
            background-color: #fff;
            box-sizing: border-box;
            margin-left: 12px;
            margin-bottom: 12px;
            justify-content: space-between;
            cursor: pointer;
            .item-info-title {
              font-size: 20px;
              font-weight: 500;
              line-height: 24px;
              color: #252a33;
            }
            .item-info-sub {
              font-size: 12px;
              line-height: 16px;
              color: #252a33;
              margin-top: 8px;
            }
            .item-img {
              width: 44px;
              height: 44px;
              display: block;
              // background-color: #ccc;
            }
          }
        }
      }
    }
  }
  .content {
    display: flex;
    flex-flow: column nowrap;
    max-width: 1180px;
    width: 100%;
    border-radius: 4px;
    background-color: #fff;
    box-sizing: border-box;
    margin: 0 auto 20px;
    padding: 28px 30px;
    .content-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 28px;
      .content-title-text {
        font-size: 15px;
        color: #252a33;
        font-weight: 500;
        line-height: 24px;
      }
      .content-title-more {
        font-size: 13px;
        color: #00c792;
        cursor: pointer;
      }
    }
    .question-module {
      display: flex;
      flex-flow: row;
      margin-bottom: 12px;
      justify-content: space-around;
      .question-card {
        border-radius: 4px;
        border: 1px solid #e8e9eb;
        background-color: #fff;
        display: block;
        flex: 1 1;
        &:hover {
          cursor: pointer;
          box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
        }
        &:nth-child(2n) {
          margin-left: 20px;
        }
        .dialog-ask {
          display: flex;
          padding: 24px 20px;
          min-height: 100px;
          box-sizing: border-box;
          .common-avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            text-align: center;
            line-height: 50px;
            color: #00c792;
            background-color: rgba(0, 199, 146, 0.2);
            font-size: 20px;
            font-weight: 500;
          }
        }
        .dialog-answer {
          display: flex;
          padding: 24px 20px;
          background: hsla(0, 0%, 96%, 0.4);
          .avatar-default {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #f1f1f1;
          }
        }
        .dialog-ask-content {
          flex: 1 1;
          font-size: 16px;
          line-height: 24px;
          color: #444c57;
          margin-left: 12px;
          display: -webkit-box;
          word-break: break-all;
          overflow: hidden;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          white-space: normal !important;
          font-family: PingFang SC, Helvetica Neue, Helvetica, Hiragino Sans GB,
            Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial, sans-serif;
        }
      }
    }
    .article-module {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  .footer-view {
    width: 100%;
    height: 50px;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: #ffffff;
    }
    a {
      color: #ffffff;
      text-decoration: underline;
      padding-left: 16px;
    }
  }
}
@media screen and (max-width: 900px) {
  .xwHome {
    .header-container {
      max-width: 1180px;
      width: 100%;
      height: 30px;
      padding: 0 20px;
      .home-logo {
        height: 30px;
        color: #000;
        text-align: center;
        line-height: 30px;
        font-size: 15px;
        .logo-img {
          width: 30px;
          height: 30px;
          border-radius: 10px;
          background-color: #ffffff;
          margin-right: 10px;
        }
      }
      .my-question,
      .login-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 15px;
        color: #000;
        &:hover {
          cursor: pointer;
          color: #00c792;
        }
        .avatar {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }
    .banner {
      width: 100%;
      height: auto;
      max-height: 1024px;
      min-height: 230px;
      background: url(../../assets/img/xwTeacher/banner-bg.jpeg) no-repeat;
      background-size: contain;
      .nav {
        display: none;
      }
    }
    .home-header {
      width: 100%;
      margin-bottom: 0px;
      padding-bottom: 30px;
      height: auto;
      background-color: #ffffff;
      .home-header-container {
        background: url(../../assets/img/xwTeacher/home-header-bg.png) no-repeat;
        background-size: contain;
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: column nowrap;
        .home-logo {
          height: 49px;
          margin-top: 20px;
          color: #ffffff;
          text-align: center;
          line-height: 49px;
          font-size: 18px;
          display: flex;
          align-items: center;
          .logo-img {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            background-color: #ffffff;
            margin-right: 10px;
          }
        }
        .search-input-container {
          margin-top: 0px;
          box-shadow: 0 2px 1px 0 hsla(215, 9%, 63%, 0.05);
          display: flex;
          align-items: center;
          position: relative;
          width: 80%;
          height: 33px;
          background-color: #fff;
          .search-input {
            display: inline-block;
            flex: 1 1;
            text-indent: 10px;
            border: 1px solid #e8e9eb;
            font-size: 14px;
            height: 33px;
            line-height: 33px;
            color: #444c57;
            outline: none;
            font-family: PingFang SC, Helvetica Neue, Helvetica,
              Hiragino Sans GB, Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial,
              sans-serif;
          }
          .search-input-button {
            width: 66px;
            height: 35px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #00c792;
            font-weight: 500;
            line-height: 16px;
            color: #fff;
            border: none;
            outline: none;
            cursor: pointer;
          }
        }
        .home-banner {
          display: flex;
          width: 100%;
          margin-top: 40px;
          justify-content: center;
          .banner-left {
            width: 226px;
            min-width: 226px;
            height: 252px;
            border-radius: 4px;
            box-shadow: 0 6px 12px 0 hsla(215, 9%, 63%, 0.2);
            background-image: url(../../assets/img/xwTeacher/banner-left-bg.png);
            background-size: contain;
            background-repeat: no-repeat;
            padding: 36px 20px;
            box-sizing: border-box;
            background-color: #fff;
            display: block;
            cursor: pointer;
            .banner-left-title {
              top: 36px;
              font-size: 28px;
              font-weight: 500;
              line-height: 32px;
              color: #252a33;
              font-family: PingFang SC, Helvetica Neue, Helvetica,
                Hiragino Sans GB, Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial,
                sans-serif;
            }
            .banner-left-sub {
              font-size: 12px;
              line-height: 16px;
              color: #252a33;
              margin-top: 8px;
              font-family: PingFang SC, Helvetica Neue, Helvetica,
                Hiragino Sans GB, Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial,
                sans-serif;
            }
          }
          .banner-right {
            display: none;
          }
        }
      }
    }
    .content {
      display: flex;
      flex-flow: column nowrap;
      max-width: 1180px;
      width: 100%;
      border-radius: 4px;
      background-color: #fff;
      box-sizing: border-box;
      margin: 0 auto 0;
      padding: 0 20px 20px;
      .content-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .content-title-text {
          font-size: 15px;
          color: #252a33;
          font-weight: 500;
          line-height: 24px;
        }
        .content-title-more {
          font-size: 13px;
          color: #00c792;
          cursor: pointer;
        }
      }
      .question-module {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        margin-bottom: 12px;
        justify-content: space-around;
        .question-card {
          border-radius: 4px;
          border: 1px solid #e8e9eb;
          background-color: #fff;
          display: block;
          flex: auto;
          margin-bottom: 10px;
          &:nth-child(2n) {
            margin-left: 0px;
          }
          .dialog-ask {
            display: flex;
            padding: 24px 20px;
            min-height: 100px;
            box-sizing: border-box;
            .common-avatar {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              text-align: center;
              line-height: 50px;
              color: #00c792;
              background-color: rgba(0, 199, 146, 0.2);
              font-size: 20px;
              font-weight: 500;
            }
          }
          .dialog-answer {
            display: flex;
            padding: 24px 20px;
            background: hsla(0, 0%, 96%, 0.4);
            .avatar-default {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background-color: #f1f1f1;
            }
          }
          .dialog-ask-content {
            flex: 1 1;
            font-size: 13px;
            line-height: 24px;
            color: #444c57;
            margin-left: 12px;
            display: -webkit-box;
            word-break: break-all;
            overflow: hidden;
            -webkit-line-clamp: 3;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            white-space: normal !important;
            font-family: PingFang SC, Helvetica Neue, Helvetica,
              Hiragino Sans GB, Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, Arial,
              sans-serif;
          }
        }
      }
      .article-module {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
    .footer-view {
      flex-direction: column;
    }
  }
}
</style>
